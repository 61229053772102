import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async';
import Markdown from 'react-markdown';
import { APP_USE_GUIDE } from '../../../const/const';

const AppCreate = () => {
  return (
    <div className='checkListReWrap'>
      <Helmet>
        <title>AiAppㅣ앱 생성ㅣ생성중</title>
        <meta name="author" content="mBaaS Inc." />
        <meta name="description" content="앱 생성 중 페이지입니다. 요청하신 앱이 자동으로 생성되고 있습니다. 완료될 때까지 잠시만 기다려 주세요."/>
        <meta name="keywords" content="AiApp, 앱 생성, 코딩, 앱 빌드, 앱 창업, 앱 개발, 엠바스, 에이아이앱, 앱만드는방법"/>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="og:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="og:url" content="https://aiapp.help/" />
        <meta property="og:images" content="../../../../public/img/opengraph.jpg" />
        <meta property="twitter:title" content="AiApp - IT를 몰라도 누구나 쉽고 빠르게, 나만의 무료앱 만들기" />
        <meta property="twitter:description" content="앱 개발이 어려운 분들을 위한 전문 솔루션, AiApp"/>
        <meta property="twitter:image" content="../../../../public/img/opengraph.jpg"/>
        <meta property="twitter:url" content="https://aiapp.help/" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <main className='checkListCon'>
        <div className='wrap d-flex align-items-center'>
          <div className='centerCon complete'>
            <h2>앱 생성이 진행중입니다!</h2>
            <p>마이페이지에서 QR 코드를 카메라로 촬영하여 앱을 다운로드 받은 후 해당 앱을 테스트해 보실 수 있습니다!<br />
            (현재는 안드로이드 기반 OS에서만 테스트가 가능합니다.)</p>
            <div className='btnWrap'>
              <Link to={"/info"}><button className='off'>마이페이지 이동</button></Link>
              <Link to={"/manual"}><button>AiApp 메뉴얼</button></Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  )}

  
export default AppCreate