import { ACCESS_TOKEN, AES_SECREY_KEY, GET_METHOD } from "../const/const";
import CryptoJS from 'crypto-js';
import { define } from "../server/define/define";
import { BUILD_HOST, CDN_HOST, FILE_HOST, HOST } from "../server/host";
import { LOG_LEVEL } from "./log-level";

/**
 * 
 * @returns 100이내의 랜덤한 숫자를 반환
 */
export const randomNumber = () => {
    return Math.floor(Math.random()
        * (100 - 10 + 1)) + 10;
};

/**
 * 범위내의 랜덤한 숫자를 반환
 * @param {number} min 최소 범위설정
 * @param {number} max 최대 범위설정
 * @returns number
 */
export const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
};

/**
 * arr배열의 index의 값을 newValue로 치환
 * @param {array} arr 치환시킬 값이 포함되어 있는 배열
 * @param {number} index 치환시킬 값의 인덱스
 * @param {any} newValue 치환시킬 값
 * @returns 
 */
export const replaceItemAtIndex = (arr, index, newValue) => {
    return [...arr.slice(0, index), newValue, ...arr.slice(index + 1, arr.length)];
}

/**
 * arr배열의 index에 해당하는 값을 삭제 해준다.
 * @param {*} arr 삭제 시킬 값이 포함된 배열
 * @param {*} index 삭제 시킬 값의 index
 * @returns 
 */
export const removeItemAtList = (arr, index) => {
    return [...arr.slice(0, index), ...arr.slice(index + 1, arr.length)];
}

/**
 * define.js를 기반으로 통신하는 통신함수
 * @param {*} defineName define.js에 등록된 객체의 key값
 * @param {*} param 통신시 서버에 넘길 파라미터값
 * @param {*} header header에 추가적으로 설정할 값
 * @returns 
 */
export const fetchData = async (defineName, param, header) => {
    let headers = {
        "Content-Type": "application/json",
    }

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    }

    if (header) {
        headers = {
            ...headers,
            ...header
        }
    }

    const opt = {
        method: define[defineName].method,
        headers: headers,
        credentials: "include",
    };

    let newUrl = define[defineName].url;

    if (opt.method !== GET_METHOD) {
        opt.body = JSON.stringify(param);
    } else {
        newUrl += "?";
        for (let key in param) {
            newUrl += `${key}=${param[key]}`
        }
    }
    fn_Debug(newUrl);

    const response = await fetch(HOST + newUrl, opt);

    if (response.headers.get("Authorization")) {
        localStorage.setItem(ACCESS_TOKEN, response.headers.get("Authorization"));
    }

    // if((response.status === 401 || response.status === 403) && defineName !== LOGOUT_DEFINE) {
    //     throw new Response(response.body, {status: response.status});
    // }
    // if(response.status >= 500) {
    //     throw new Response(response.body, {status: response.status});
    // }

    const json = await response.json();

    // if(json.result === "FAIL") {
    //     throw new Response(json.message, {status: 500});
    // }

    fn_Debug(defineName);
    fn_Debug(json);
    fn_Debug(param);

    return json;
}

/**
 * PDF 데이터 요청 함수
 * @param {string} defineName define.js에 등록된 객체의 key값
 * @param {object} param 서버로 보내는 파라미터값
 * @param {object} header 추가적으로 설정할 헤더값
 * @returns 응답 객체
 */
export const fetchPDF = async (defineName, param, header = {}) => {
    let headers = {
        "Content-Type": "application/json",
        ...header
    };

    const token = localStorage.getItem(ACCESS_TOKEN);
    if (token) {
        headers.Authorization = `Bearer ${token}`;
    }

    const opt = {
        method: define[defineName].method,
        headers: headers,
        credentials: "include",
    };

    let newUrl = define[defineName].url;

    if (opt.method !== GET_METHOD) {
        opt.body = JSON.stringify(param);
    } else {
        const queryParams = new URLSearchParams(param).toString();
        newUrl += `?${queryParams}`;
    }

    fn_Debug(newUrl);

    const response = await fetch(HOST + newUrl, opt);

    if (response.headers.get("Authorization")) {
        localStorage.setItem(ACCESS_TOKEN, response.headers.get("Authorization"));
    }

    fn_Debug(defineName);
    fn_Debug(param);

    return response; // PDF 데이터는 Blob 형태이므로 응답 객체를 반환
}

/**
 * define.js를 기반으로 통신하는 통신함수
 * @param {*} defineName define.js에 등록된 객체의 key값
 * @param {*} param 통신시 서버에 넘길 파라미터값
 * @param {*} header header에 추가적으로 설정할 값
 * @returns 
 */
export const fetchBuilder = async (defineName, param, header) => {
    let headers = {
        "Content-Type": "application/json",
    }

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    }

    if (header) {
        headers = {
            ...headers,
            ...header
        }
    }

    const opt = {
        method: define[defineName].method,
        headers: headers,
        credentials: "include",
    };

    let newUrl = define[defineName].url;

    if (opt.method !== GET_METHOD) {
        opt.body = JSON.stringify(param);
    } else {
        newUrl += "?";
        for (let key in param) {
            newUrl += `${key}=${param[key]}`
        }
    }
    fn_Debug(newUrl);

    const response = await fetch(BUILD_HOST + newUrl, opt);

    if (response.headers.get("Authorization")) {
        localStorage.setItem(ACCESS_TOKEN, response.headers.get("Authorization"));
    }

    // if((response.status === 401 || response.status === 403) && defineName !== LOGOUT_DEFINE) {
    //     throw new Response(response.body, {status: response.status});
    // }
    // if(response.status >= 500) {
    //     throw new Response(response.body, {status: response.status});
    // }

    const json = await response.json();

    // if(json.result === "FAIL") {
    //     throw new Response(json.message, {status: 500});
    // }

    fn_Debug(defineName);
    fn_Debug(json);
    fn_Debug(param);

    return json;
}


/**
 * 기존 통신모듈과 같은 역할을 하지만 param으로 넘어오는 값은 pathvariable로 queryStrings로 넘어오는 값은 queryString으로 넘겨준다.
 * @param {*} defineName define.js에 등록된 객체의 key값
 * @param {object} param 통신시 서버에 넘길 파라미터값
 * @param {object} queryStrings queryString으로 넘길 값
 * @returns 
 */
export const fetchDataPathVar = async (defineName, param, queryStrings) => {
    let newUrl = HOST + define[defineName].url;

    for (let value in param) {
        newUrl += `/${param[value]}`;
    }

    if (queryStrings) {
        newUrl += "?";
        for (let value in queryStrings) {
            newUrl += `${value}=${queryStrings[value]}&`
        }
    }

    const headers = {
        "Content-Type": "application/json",
    }

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    }

    const response = await fetch(newUrl, {
        method: define[defineName].method,
        headers: headers,
    });

    if (response.headers.get("Authorization")) {
        localStorage.setItem(ACCESS_TOKEN, response.headers.get("Authorization"));
    }

    if (response.status === 401 || response.status === 403) {
        throw new Response(response.body, { status: response.status });
    }
    if (response.status >= 500) {
        throw new Response(response.body, { status: response.status });
    }

    const json = await response.json();

    if (json.errorCode === "COMMON_NO_LOGIN") {
        throw new Response(json.message, { status: 401 });
    }

    fn_Debug(defineName);
    fn_Debug(json);

    return json;
}

/**
 * log-level.js 에 설정된 LOG_LEVEL의 값이 DEBUG일 경우 로그 출력시켜주는 함수
 * @param {*} data 
 */
export const fn_Debug = (data) => {
    if (LOG_LEVEL === "DEBUG") {
        console.debug(data);
    }
}

/**
 * formData로 받아온 값을 object로 변환시켜주는 함수.
 * requiredListKey에 포함되어 있는 key값은 리스트로 변환해줌.
 * 해당 파라미터가 생긴 이유는 일반적으로 변환을 시키면 form태그 내부에 값이 여러개있는경우 리스트로 변환하지않고 하나의 값으로 덮어 쓰기때문에
 * 가장 마지막에 존재하는 값만 담기는 현상이 발생. 따라서 해당 requiredListKey에 포함되어 있는 값은 리스트로 변환한다는 뜻에서 생성.
 * @param {formData} formData 
 * @param {object} reqiredListKey 
 * @returns 
 */
export const makeParamsFromFormData = (formData, reqiredListKey) => {
    const keys = formData.keys();
    const params = {}

    for (let key of keys) {
        if (key === "file") continue;

        if (typeof reqiredListKey === "object") {
            for (let rk of reqiredListKey) {
                if (key === rk) {
                    const rkey = formData.getAll(rk);
                    if (typeof rkey === "object") {
                        params[rk] = rkey;
                    } else if (rkey === "string") {
                        params[rk] = [rkey];
                    }
                    continue;
                }
            }
        } else {
            if (key === reqiredListKey) {
                const rkey = formData.getAll(reqiredListKey);
                if (typeof rkey === "object") {
                    params[reqiredListKey] = rkey;
                } else if (rkey === "string") {
                    params[reqiredListKey] = [rkey];
                }
                continue;
            }
        }

        if (params[key]) {
            params[key] = formData.getAll(key);
        } else {
            params[key] = formData.get(key);
        }
    }

    return params;
}

/**
 * 파일서버에 이미지등의 파일을 업로드 시킬때 사용하는 통신모듈
 * @param {*} defineName define.js에 등록된 객체의 key값
 * @param {formData} formData 통신시 서버에 넘길 파라미터값
 * @returns 
 */
export const uploadFile = async (defineName, formData) => {
    const newFormData = new FormData();

    for (let [name, value] of formData) {
        if (name === "file") {
            if (value.size === 0) {
                continue;
            }
        }
        newFormData.append(name, value);
    }

    const opt = {
        method: define[defineName].method,
        body: newFormData,
    };

    const response = await fetch(FILE_HOST + define[defineName].url, opt);
    if (response.status >= 500) {
        throw new Error(response.body);
    }

    const json = await response.json();

    fn_Debug(defineName);
    fn_Debug(json);

    return json.fleGrpId;
}

/**
 * ck-editor가 사용하는 파일 업로드 통신모듈
 * @param {*} defineName 
 * @param {*} formData 
 * @returns 
 */
export const uploadFileEditor = async (defineName, formData) => {
    const opt = {
        method: define[defineName].method,
        body: formData,
    };

    const response = await fetch(FILE_HOST + define[defineName].url, opt);
    if (response.status >= 500) {
        throw new Error(response.body);
    }

    const json = await response.json();

    fn_Debug(defineName);
    fn_Debug(json);

    return json.url;
}

/**
 * 파일을 호출하는 통신모듈
 * @param {*} defineName 
 * @param {*} param 
 * @param {*} queryStrings 
 * @returns 
 */
export const getFiles = async (defineName, param, queryStrings) => {
    let newUrl = CDN_HOST + define[defineName].url;

    for (let value in param) {
        newUrl += `/${param[value]}`;
    }

    if (queryStrings) {
        newUrl += "?";
        for (let value in queryStrings) {
            newUrl += `${value}=${queryStrings[value]}&`
        }
    }

    const headers = {
        "Content-Type": "application/json",
    }

    if (localStorage.getItem(ACCESS_TOKEN)) {
        headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
    }

    const response = await fetch(newUrl, {
        method: define[defineName].method,
        headers: headers,
    });

    if (response.headers.get("Authorization")) {
        localStorage.setItem(ACCESS_TOKEN, response.headers.get("Authorization"));
    }

    if (response.status >= 500) {
        throw new Error(response.body);
    }

    const json = await response.json();

    fn_Debug(defineName);
    fn_Debug(json);

    return json;
}

/**
 * date객체를 형식에 맞게 string으로 변환
 * @param {Date} date 
 * @returns 
 */
export const dateFormat = (date) => {
    if (!date) return null;
    let dateFormat2 =
        date.getFullYear() +
        '.' + ((date.getMonth() + 1) <= 9 ? "0" + (date.getMonth() + 1) : (date.getMonth() + 1)) +
        '.' + ((date.getDate()) <= 9 ? "0" + (date.getDate()) : (date.getDate())) +
        '. ' + ((date.getHours()) <= 9 ? "0" + (date.getHours()) : (date.getHours())) +
        ':' + ((date.getMinutes()) <= 9 ? "0" + (date.getMinutes()) : (date.getMinutes()));
    return dateFormat2;
}

/**
 * 인코딩 모듈
 * @param {*} val 
 * @returns 
 */
export const encrypt = (val) => {
    const text = val.toString();

    const ect = CryptoJS.AES.encrypt(text, AES_SECREY_KEY);
    let result = ect.toString()

    return encodeURIComponent(result);
}

/**
 * 디코딩 모듈
 * @param {*} ect 
 * @returns 
 */
export const decrypt = (ect) => {
    const dct = CryptoJS.AES.decrypt(ect, AES_SECREY_KEY);
    return dct.toString(CryptoJS.enc.Utf8);
}