import React from 'react'
import { Link } from 'react-router-dom'

const Manual = () => {
  return (
    <>
    <main>
      <div className='manual'>
        <div className='manual-top'>
          <div>
            <Link to={"/info"}><img src={`${process.env.PUBLIC_URL}/img/icon_backB.png`} alt="뒤로가기 아이콘"/><button>마이페이지로</button></Link>
          </div>
        </div>
        <iframe src="https://mbaas-inc.github.io/" frameborder="0"></iframe>
      </div>
    </main>
    </>
  )
}

export default Manual 